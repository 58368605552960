<script setup lang="ts">
import type { Week } from '~/models/Planner/Week'
import type { PlannedItem } from '~/models/Content/PlannedItem'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, inject, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsSpinner } from '@aschehoug/kloss'
import arrayUtils from '~/utils/arrayUtils'
import useWeekStore from '~/stores/week'
import usePlannerStore from '~/stores/planner'
import { useAuthStore } from '~/stores/auth'
import { YearPlanRole } from '~/models/Planner/YearPlan'
import { Icon } from '~/models/Icons'
import ConditionalTooltip from '~/components/utils/ConditionalTooltip.vue'

const props = defineProps<{
  resource: PlannedItem|BaseItem
}>()

const { t } = useI18n()
const { intersect } = arrayUtils()
const toast = <Toast>inject('ksToast')

const plannerStore = usePlannerStore()
const { removeFromWeek, removeResource } = plannerStore
const { yearPlans } = storeToRefs(plannerStore)
const { selectedWeekNumber } = storeToRefs(useWeekStore())
const { userId, isTeacher, hasOrganization } = storeToRefs(useAuthStore())

const isLoadingResource = ref(false)

/**
 * Finds the yearPlan of this resource
 */
const yearPlan = computed(() => yearPlans.value
  .find(({ identifier }) => identifier && props.resource?.yearPlan && identifier === props.resource.yearPlan))

/**
 * A user can remove a planned if
 *
 * 1. You created the year plan
 * 2. You are an editor of the year plan
 */
const canRemove = computed(() => {
  if (!yearPlan.value) return false
  return (yearPlan.value.createdBy && yearPlan.value.createdBy.username === userId.value)
    || intersect(yearPlan.value.roles, [YearPlanRole.Owner, YearPlanRole.Editor]).length > 0
})

const resourceIsPlanned = computed(() => findWeekWithResource(props.resource))

/**
 * Finds week with resource in all years plans matching by identifier (i.e exact match)
 *
 * @param resource
 */
function findWeekWithResource(resource: PlannedItem): Week|undefined {
  if (!yearPlan.value) return undefined
  return yearPlan.value.plans.flatMap(({ weeks }) => weeks
    .filter(({ weekNumber, resources }) => weekNumber === selectedWeekNumber.value
    && resources.some(({ identifier }) => identifier === resource.identifier)))[0]
}

/**
 * Try to remove the week from this resources' year plan
 *
 * @param resource
 */
async function removeResourceFromWeek(resource: PlannedItem) {
  if (!yearPlan.value) {
    toast.error(t('planner.addToWeek.noYearPlan'))
    return
  }

  const week = findWeekWithResource(resource)
  if (!week) {
    toast.error(t('planner.addToWeek.noWeek'))
    return
  }

  isLoadingResource.value = true
  try {
    await removeFromWeek(week, resource)
    toast.success(t('planner.addToWeek.removed'))
    if (!findWeekWithResource(resource)) {
      await removeResource(resource.identifier)
    }
  } catch (error) {
    toast.error(t('planner.addToWeek.failed'))
    throw error
  } finally {
    isLoadingResource.value = false
  }
}
</script>

<template>
  <ConditionalTooltip
    :condition="!canRemove"
    :text="t('planner.addToWeek.noRemove')"
    direction="right"
  >
    <KsButton
      v-if="isTeacher && hasOrganization && resourceIsPlanned"
      ref="buttonRef"
      :icon-left="isLoadingResource ? '' : Icon.Minus"
      variant="secondary"
      :disabled="!canRemove || isLoadingResource"
      size="small"
      @click="removeResourceFromWeek(resource)"
    >
      <KsSpinner
        v-if="isLoadingResource"
        size="small"
        class="m-0 size-5"
      />
      <span
        v-else
        v-text="t('remove')"
      />
    </KsButton>
  </ConditionalTooltip>
</template>
