<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { faArrowLeft, faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsIcon } from '@aschehoug/kloss'
import useLicenseControlStore from '~/stores/licenseControl'
import { useAuthStore } from '~/stores/auth'
import usePaywallOverlay from '~/composables/usePaywallOverlay'

const { t } = useI18n()
const licenseControlStore = useLicenseControlStore()
const { isTeacher } = storeToRefs(useAuthStore())
const { resetLicenseControlStore } = licenseControlStore
const { hasAccessRestricted } = storeToRefs(licenseControlStore)
const { washPage } = usePaywallOverlay()

const webshopUrl = computed(() => <string>import.meta.env.VITE_WEBSHOP_TRIALURL)

const back = () => {
  resetLicenseControlStore()
  window.location.href = '/'
}
</script>

<template>
  <Transition
    v-if="hasAccessRestricted"
    tag="div"
    class="paywall-overlay-ignore"
    enter-active-class="duration-500 delay-500"
    enter-from-class="translate-y-full opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    appear
    paywall-overlay-ignore
    @after-enter="washPage"
  >
    <div class="absolute h-screen w-screen bg-gradient-to-b from-transparent via-white to-white">
      <div class="container mx-auto h-screen">
        <div class="absolute inset-x-0 bottom-10 z-20 m-auto h-fit max-w-prose rounded-lg border-gray-10 bg-white p-10 text-center sm:bottom-32 sm:border-2">
          <div class="mb-3 inline-flex rounded-full bg-seagreen-10/50 p-2 text-seagreen-50">
            <KsIcon
              icon="lock"
              :scale="1.8"
            />
          </div>
          <h3
            class="text-2xl font-bold"
            v-text="t('licenseControl.message.title')"
          />
          <p
            class="mb-5 mt-3"
            v-text="isTeacher ? t('licenseControl.message.body.teacher') : t('licenseControl.message.body.student')"
          />
          <div
            class="grid w-full grid-cols-1 justify-center gap-4"
            :class="{ 'sm:grid-cols-2': isTeacher }"
          >
            <KsButton
              href="/"
              variant="primary"
              size="medium"
              shape="normal"
              :icon-left="faArrowLeft"
              center
              @click.prevent="back"
            >
              {{ t('licenseControl.buttons.back') }}
            </KsButton>
            <KsButton
              v-if="isTeacher"
              variant="secondary"
              size="medium"
              :href="webshopUrl"
              :icon-left="faUpRightFromSquare"
              target="_blank"
              center
            >
              {{ t('licenseControl.buttons.webshop') }}
            </KsButton>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<style>
.paywall-overlay-ignore {
  z-index: 300001 !important; /* more than Pendo guides which is 300000 */
}
</style>
