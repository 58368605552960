import type { ComputedRef, Ref } from 'vue'
import type { Favorite, UpToDateFavorite, FavoriteCreateInput, FavoriteList, UpToDateFavoriteList, FavoriteListCreateInput } from '~/models/Favorites'
import type { ContentFile } from '~/models/Content/ContentFile'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, watch } from 'vue'
import { useQuery, useMutation, useQueryClient, useQueries } from '@tanstack/vue-query'
import useProductStore from '~/stores/product'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useSearchHelper from '~/composables/useSearchHelper'
import { useFavoritesApi } from '~/api/favoritesApi'
import useContentApi from '~/api/contentApi'

const QUERY_KEY: Readonly<string> = 'favorite-lists'

export const useFavorites = () => {
  const {
    getFavoriteLists: apiGetFavoriteLists,
    updateFavoriteList: apiUpdateFavoriteList,
    deleteFavoriteList: apiDeleteFavoriteList,
    createFavoriteList: apiCreateFavoriteList,
    createFavorite: apiCreateFavorite,
    removeFavoriteFromList: apiRemoveFavoriteFromList,
  } = useFavoritesApi()
  const { findContents } = useContentApi()
  const { getProductFromResource } = useProductStore()
  const { defaultContentTypes } = useSearchHelper()
  const { buildResourceUrl } = useUrlBuilder()

  const queryClient = useQueryClient()

  const addItemToList = useMutation({
    mutationFn: ({ item, list }: { item: FavoriteCreateInput; list: FavoriteList }) => apiCreateFavorite(item, list),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] })
  })

  const deleteFavoriteList = useMutation({
    mutationFn: (list: FavoriteList) => apiDeleteFavoriteList(list),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] }),
  })

  const updateFavoriteList = useMutation({
    mutationFn: (updatedList: FavoriteList) => apiUpdateFavoriteList(updatedList),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] }),
  })

  const createFavoriteList = useMutation({
    mutationFn: (newList: FavoriteListCreateInput) => apiCreateFavoriteList(newList),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] }),
  })

  const removeFavoriteFromList = useMutation({
    mutationFn: ({ item, list }: { item: Favorite; list: FavoriteList }) => {
      console.log({ list, item })
      return apiRemoveFavoriteFromList({ item, list })
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] }),
  })

  const favoriteLists = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => apiGetFavoriteLists(),
    staleTime: Infinity,
  })

  const locationIds = computed(() =>
    favoriteLists
      .data
      .value
      ?.flatMap(({ favorites }) => favorites?.map(({ aunivers }) => aunivers.locationId) ?? [])
      .map((id) => Number(id))
    ?? []
  )

  const favorites = useQuery({
    queryKey: [QUERY_KEY, 'contents', locationIds],
    queryFn: () => {
      return findContents<BaseItem | ContentFile>({
        locationIdCriterion: locationIds.value,
        contentTypeCriterion: defaultContentTypes,
      }, locationIds.value.length)
    },
    enabled: computed(() => locationIds.value.length > 0),
    staleTime: Infinity,
  })

  const getUpToDateFavoriteLists = () => {
    const isLoading = computed(() => favoriteLists.isLoading.value || favorites.isLoading.value)
    const data = computed(() => {
      return favoriteLists.data.value
        ?.map((list) => ({
          ...list,
          favorites: list
            .favorites
            ?.map((favorite) => {
              const content = favorites.data.value?.find(({ locationId }) => Number(locationId) === Number(favorite.aunivers.locationId))
              return !content ? null : {
                ...favorite,
                aunivers: content,
              }
            })
            .filter((favorite): favorite is UpToDateFavorite => favorite != null)
            ?? []
        }))
        ?? []
    })

    return {
      isLoading,
      data,
    }
  }

  return {
    createFavoriteList,
    deleteFavoriteList,
    removeFavoriteFromList,
    updateFavoriteList,
    getFavoriteLists: favoriteLists,
    getUpToDateFavoriteLists,
    addItemToList,
    locationIds,
  }
}
