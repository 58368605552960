<script setup lang="ts">
import type { Component } from 'vue'
import type { ArticleContent } from '~/models/Content/ArticleContent'
import { computed } from 'vue'
import { ArticleContentVariation } from '~/models/Content/ArticleContentVariation'
import ArticleTextAndImage from '~/components/article/TextAndImage.vue'
import ArticleText from '~/components/article/Text.vue'

const props = defineProps<{
  articleContent: ArticleContent
}>()

const componentMap: Record<string, Component> = {
  [ArticleContentVariation.Text]: ArticleText,
  [ArticleContentVariation.TextAndImage]: ArticleTextAndImage,
  [ArticleContentVariation.TextOnImage]: ArticleText,
  [ArticleContentVariation.TextBoxDashed]: ArticleText,
  [ArticleContentVariation.TextBoxSolid]: ArticleText,
  [ArticleContentVariation.TextReflection]: ArticleText,
}

const renderedComponent = computed(() => componentMap[props.articleContent.variation.identifier])
</script>

<template>
  <component
    :is="renderedComponent || ArticleText"
    :article-content="props.articleContent"
  />
</template>
