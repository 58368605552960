<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsSkeleton } from '@aschehoug/kloss'
import useSubscriptionsStore from '~/stores/subscriptions'
import { useAuthStore } from '~/stores/auth'
import useSubscription from '~/composables/useSubscription'
import useSettings from '~/composables/useSettings'
import AuLogo from '~/components/utils/AuLogo.vue'

const { t } = useI18n()
const { selectedGradeType, isTeacher } = storeToRefs(useAuthStore())
const { relevantSubscription, isLoadingSubscriptions } = storeToRefs(useSubscriptionsStore())
const { toggleSettings, toggleSubscriptions } = useSettings()
const { isPlusOrPremium } = useSubscription()

const openSubscriptionTab = () => {
  toggleSettings()
  toggleSubscriptions()
}

</script>
<template>
  <div
    v-if="!isLoadingSubscriptions && relevantSubscription && isTeacher"
    class="relative h-full max-h-max min-w-[200px] content-center self-start rounded-2xl bg-seagreen-60 p-6 text-base shadow-md motion-safe:animate-fade"
  >
    <div
      v-if="isPlusOrPremium(relevantSubscription)"
      class="absolute -left-2 -top-2 size-[75px] overflow-hidden before:absolute before:right-0 before:top-0 before:z-[-1] before:block before:border-4 before:border-yellow-40 before:border-l-transparent before:border-t-transparent before:content-[''] after:absolute after:bottom-0 after:left-0 after:z-[-1] after:block after:border-4 after:border-yellow-40 after:border-l-transparent after:border-t-transparent after:content-['']"
    >
      <span class="absolute right-[-69px] top-[20px] block w-[225px] -rotate-45 bg-yellow-pop p-0 text-center text-sm font-medium uppercase text-seagreen-50 shadow-[0_5px_10px_rgba(0,0,0,0.1)]">
        {{ t(`subscription.type.${relevantSubscription?.type}`).toUpperCase() }}
      </span>
    </div>
    <div class="flex flex-col items-center gap-2">
      <AuLogo class="h-10 w-auto text-white" />
      <div class="mt-2 flex gap-2">
        <KsButton
          variant="primary"
          size="small"
          class="subscription-button tracking-widest"
          @click="toggleSettings()"
        >
          {{ t(`metadata.gradeTypes.short.${selectedGradeType}`) }}
        </KsButton>
        <KsButton
          variant="primary"
          size="small"
          class="subscription-button tracking-wide"
          @click="openSubscriptionTab()"
        >
          {{ t(`subscription.type.${relevantSubscription?.type}`).toUpperCase() }}
        </KsButton>
      </div>
    </div>
  </div>
  <KsSkeleton
    v-else-if="isLoadingSubscriptions"
    height="138px"
    width="200px"
    :background="'rgb(var(--au-seagreen-60))'"
    class="flex self-start"
  />
</template>

<style scoped>
.subscription-button {
  --ks-primary: rgb(var(--au-seagreen-50));
  --ks-primarytext: rgb(var(--au-seagreen-pop));
  --ks-roundness: 0.3;

  &:hover {
    --ks-secondary: rgb(var(--au-seagreen-pop));
    --ks-primarytext: rgb(var(--au-seagreen-50));
  }
}
</style>
