<script setup lang="ts">
import type { Product } from '~/models/Product'
import type { AggregationTermEntry, SearchResult } from '~/models/Content/Response'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { KsButton, KsInput, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { sortBySubjectIndex } from '~/utils/subjectSorter'
import { gradeTypeMapping } from '~/utils/gradeSorter'
import arrayUtils from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import useAuthStore from '~/stores/auth'
import useText from '~/composables/useText'
import useAggregations from '~/composables/useSearchAggregations'
import useProductHelper from '~/composables/useProductHelper'
import useAggregationMapper from '~/composables/useAggregationMapper'
import SearchSubjectFilterBox from '~/components/search/SearchSubjectFilterBox.vue'
import FilterBox from '~/components/search/SearchFilterBox.vue'
import GlobalSearchGradeFilterBox from '~/components/search/global/GlobalSearchGradeFilterBox.vue'
import AggregationButton from '~/components/search/AggregationButton.vue'

const props = defineProps<{
  results: SearchResult
  isLoading: boolean
  isRefetching: boolean
  criterions: Record<string, string[]>
  products: Product[]
}>()

const emit = defineEmits(['toggleCriterion'])

const resultsRef = ref(props.results)
const showFilters = ref(false)

const { t } = useI18n()
const { unique, intersect } = arrayUtils()
const { capitalize } = useText()
const { isTeacher, userRelevantGrades } = storeToRefs(useAuthStore())
const { filteredSupportProducts } = storeToRefs(useProductStore())
const { isRelevantProduct } = useProductHelper()
const { mapGroupedGradeAggregations } = useAggregationMapper()
const breakpoints = useBreakpoints(breakpointsTailwind)

const mdBreakpoint = breakpoints.greater('md')

const {
  labelAggregations,
  accessAggregations,
  activityAggregations,
  sortedGradeAggregations,
} = useAggregations(resultsRef)

const groupGradeAggregations = computed(() => mapGroupedGradeAggregations(sortedGradeAggregations.value, gradeTypeMapping))

const groupedGradeAggregations = computed(() => {
  return Array.from(groupGradeAggregations.value.entries()).map(([group, entries]) => ({
    group,
    keys: entries.flatMap(entry => ({ key: entry.key, count: entry.count })),
  }))
})

const matchingSubjects = computed(() => {
  return props.products
    .filter((product) => props.criterions.grade.length
      ? intersect(product.grades, props.criterions.grade).length > 0
      : isRelevantProduct(product, userRelevantGrades.value))
    .flatMap(({ subjects }) => subjects)
    .filter(unique)
    .sort(sortBySubjectIndex)
})

watch(() => props.results, (newResults) => {
  resultsRef.value = newResults
})
</script>

<template>
  <aside class="grid-in-filters">
    <div>
      <KsButton
        v-if="!mdBreakpoint"
        variant="border"
        @click="showFilters = !showFilters"
      >
        {{ showFilters ? t('filters.hideFilters') : t('filters.chooseFilters') }}
      </KsButton>
      <div
        v-if="(!isLoading && mdBreakpoint) || (!isLoading && showFilters)"
        class="mt-4 flex flex-col gap-4"
        :style="{'--ks-roundness': .2}"
      >
        <div
          v-if="filteredSupportProducts.length"
          class="rounded bg-white"
        >
          <AggregationButton
            :name="t('search.filterBoxes.support')"
            type="label"
            :disabled="isRefetching"
            stretch
            class="!px-[2.07rem]"
          >
            <KsInput
              id="has_audio"
              type="checkbox"
              class="shrink-0"
              :checked="criterions['support']?.includes('true')"
              :disabled="isRefetching"
              @click="emit('toggleCriterion', 'support', 'true')"
            />
          </AggregationButton>
        </div>
        <SearchSubjectFilterBox
          v-if="matchingSubjects.length"
          :title="t('filters.subject')"
          :subjects="matchingSubjects"
          :is-refetching="isRefetching"
          :selected-subjects="criterions['subject'] ?? []"
          @toggle-criterion="(subjectCode) => emit('toggleCriterion', 'subject', subjectCode)"
        />
        <GlobalSearchGradeFilterBox
          :title="t('filters.grade')"
          :is-refetching="isRefetching"
          :criterions="criterions['grade'] ?? []"
          :aggregations="groupedGradeAggregations"
          :translator="(entry: AggregationTermEntry) => t(`metadata.grades.${entry.key}`)"
          @toggle-criterion="(entry) => emit('toggleCriterion', 'grade', entry)"
        />
        <FilterBox
          v-if="activityAggregations.length"
          :title="t('labels.activity')"
          :is-refetching="isRefetching"
          :aggregations="activityAggregations"
          :criterions="criterions['activity'] ?? []"
          :translator="(entry: AggregationTermEntry) => t(`activities.${entry.key}`, capitalize(entry.key))"
          @toggle-criterion="(entry) => emit('toggleCriterion', 'activity', entry)"
        />
        <FilterBox
          v-if="labelAggregations.length"
          :title="t('filters.taskType')"
          :is-refetching="isRefetching"
          :aggregations="labelAggregations"
          :criterions="criterions['label'] ?? []"
          :translator="(entry: AggregationTermEntry) => t(`labels.${entry.key}`)"
          @toggle-criterion="(entry) => emit('toggleCriterion', 'label', entry)"
        />
        <FilterBox
          v-if="isTeacher && accessAggregations.length"
          :title="t('access.title')"
          :is-refetching="isRefetching"
          :aggregations="accessAggregations"
          :criterions="criterions['access'] ?? []"
          :translator="(entry: AggregationTermEntry) => t(`access.${entry.key}`)"
          @toggle-criterion="(entry) => emit('toggleCriterion', 'access', entry)"
        />
      </div>
      <KsSkeletonWrapper
        v-if="isLoading"
        class="flex flex-col gap-4"
      >
        <KsSkeleton
          v-for="i in 6"
          :key="i"
          height="45px"
          width="100%"
          border-radius="4px"
        />
      </KsSkeletonWrapper>
    </div>
  </aside>
</template>
