<script setup lang="ts">
import type { ContentPackage } from '~/models/Content/ContentPackage'
import type { Color } from '~/models/Content/Color'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import useFilterStore from '~/stores/filter'
import { useContentHelper } from '~/composables/useContentHelper'
import useContentApi from '~/api/contentApi'
import SimpleCard from '~/components/cards/SimpleCard.vue'
import GridCard from '~/components/cards/GridCard.vue'

const { header, isDark, index, darkColor, textColor, accentColor, altCardStyle } = defineProps<{
  header: ContentPackage
  index: number
  isDark?: boolean
  darkColor?: Color
  textColor?: Color
  accentColor?: Color
  altCardStyle?: Record<string, string>
}>()

const filterStore = useFilterStore()
const { contentFilters } = filterStore
const { gradeFilterFunc } = contentFilters
const { selectedGrade } = storeToRefs(filterStore)
const { isContentHeader } = useContentHelper()
const { findContents } = useContentApi()

const children = computed(() => data?.value ? data.value.filter((content) => gradeFilterFunc(content)) : [])
const isGrid = computed(() => isContentHeader(header) && header.viewMode === 'grid')

const { data } = useQuery({
  enabled: !!header.mainLocationId,
  queryKey: ['header-children', header.mainLocationId, selectedGrade.value],
  queryFn: () => findContents({
    parentLocationIdCriterion: [header.mainLocationId],
    sortField: header.sortField,
    sortOrder: header.sortOrder,
  }, 100),
  staleTime: Infinity,
})
</script>
<template>
  <section class="flex flex-col gap-4">
    <h2
      class="font-tiempos text-4xl"
      v-text="header.title"
    />
    <ul
      class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
      :class="isGrid ? 'gap-6' : 'gap-[1px]'"
    >
      <li
        v-for="childContent in children"
        :key="childContent.locationId"
        :class="isGrid && 'h-fit'"
      >
        <GridCard
          v-if="isGrid"
          :style="{
            '--card-text': isDark ? `rgb(var(${darkColor?.var}))` : `rgb(var(${textColor?.var}))`,
            '--card-bg': `rgb(var(${darkColor?.var}))`,
            '--card-border': `rgb(var(${accentColor?.var}))`,
          }"
          :resource="childContent"
          :loading="index === 0 ? 'eager' : 'lazy'"
        />
        <SimpleCard
          v-else
          :resource="childContent"
          :style="Boolean(index % 2) ? null : altCardStyle"
        />
      </li>
    </ul>
  </section>
</template>
