<script setup lang="ts">
import type { ContentPackage } from '~/models/Content/ContentPackage'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import useFilterStore from '~/stores/filter'
import useContentApi from '~/api/contentApi'
import HeaderPreviewAccordion from '~/components/subject/HeaderPreviewAccordion.vue'
import ColorCard from '~/components/cards/ColorCard.vue'

const { header, style, index, hash } = defineProps<{
  header: ContentPackage
  isCollapsable: boolean
  style: string
  index: number
  hash: string
}>()

const filterStore = useFilterStore()
const { contentFilters } = filterStore
const { gradeFilterFunc } = contentFilters
const { selectedGrade } = storeToRefs(filterStore)
const { findContents } = useContentApi()

const children = computed(() => data?.value ? data.value.filter((content) => gradeFilterFunc(content)) : [])

const { data, isLoading } = useQuery({
  enabled: !!header.mainLocationId,
  queryKey: ['header-children', header.mainLocationId, selectedGrade.value],
  queryFn: () => findContents({
    parentLocationIdCriterion: [header.mainLocationId],
    sortField: header.sortField,
    sortOrder: header.sortOrder,
  }, 100),
  staleTime: Infinity,
})
</script>
<template>
  <section :style="style">
    <KsSkeletonWrapper
      v-if="isLoading"
      class="mx-auto max-w-screen-au space-y-4 px-4 py-8 sm:px-8"
    >
      <KsSkeleton
        height="36px"
        width="120px"
      />
      <ul class="grid grid-cols-1 gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <KsSkeleton
          v-for="i in 4"
          :key="i"
          height="280px"
        />
      </ul>
    </KsSkeletonWrapper>
    <HeaderPreviewAccordion
      v-else-if="isCollapsable"
      :id="`${index+1}`"
      :title="header.title"
      :open="`#${index+1}` === hash"
    >
      <ul class="grid grid-cols-1 gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <li
          v-for="(childContent, childIndex) in children"
          :key="childContent.locationId"
        >
          <ColorCard
            :resource="childContent"
            :loading="childIndex === 0 ? 'eager' : 'lazy'"
          />
        </li>
      </ul>
    </HeaderPreviewAccordion>
    <div
      v-else
      class="mx-auto max-w-screen-au space-y-8 px-4 py-12 sm:px-8"
    >
      <h2
        class="font-calistoga fluid-text-2xl"
        v-text="header.title"
      />
      <ul class="grid grid-cols-1 gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <li
          v-for="(childContent, childIndex) in children"
          :key="childContent.locationId"
        >
          <ColorCard
            :resource="childContent"
            :loading="childIndex === 0 ? 'eager' : 'lazy'"
          />
        </li>
      </ul>
    </div>
  </section>
</template>
