import type { SubjectCode } from '~/models/Subject'
import type { Product, SupportProductName } from '~/models/Product'
import type { GradeCode } from '~/models/Grade'
import arrayUtils from '~/utils/arrayUtils'
import { ProductVariation } from '~/models/Product'

export default () => {
  const { intersect } = arrayUtils()

  const supportProductNames: SupportProductName[] = ['bokstøtte', 'lydstøtte']

  const addonProductColorMap: Record<string, string> = {
    '9788203410833': 'purple', // Biblioteket
    '9788203412783': 'orange', // Terminprøver
    '9788203412554': 'blue', // Papir+
  }

  const hasRelatedLocations = (product: Product) => {
    const metadata = product.aunivers
    return !!(metadata?.relatedLocations
      && (metadata.relatedLocations.flexibleContentLocationId
        || metadata.relatedLocations.learningPathsLocationId
        || metadata.relatedLocations.interdisciplinaryContentLocationId
        || metadata.relatedLocations.teacherContentLocationId))
  }

  const productHasSubjectAndGrade = (product: Product, grade: GradeCode, subject: SubjectCode) =>
    product.subjects.includes(subject) && product.grades.includes(grade)

  const isProductVariation = (product: Product, variation: ProductVariation) =>
    product.aunivers?.productVariation === variation

  const isRelevantProduct = (product: Product, grades: GradeCode[]) => !product.grades.length || intersect(product.grades, grades).length > 0

  const isAddonProduct = (product: Product) => isProductVariation(product, ProductVariation.Addon)

  const isSupportName = (product: Product, name: SupportProductName) => product.aunivers.name.toLowerCase().includes(name)

  const isSupportProduct = (product: Product) => supportProductNames.some((identifier) => isSupportName(product, identifier))

  const isSearchableProduct = (product: Required<{ ean: string }>): boolean => isLibrary(product)

  const isLibrary = (product: Required<{ ean: string }>) => product.ean.includes('9788203410833')

  return {
    hasRelatedLocations,
    productHasSubjectAndGrade,
    isProductVariation,
    supportProductNames,
    addonProductColorMap,
    isRelevantProduct,
    isAddonProduct,
    isSupportName,
    isSupportProduct,
    isSearchableProduct,
    isLibrary,
  }
}
