<script setup lang="ts">
import { computed, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { Settings } from 'luxon'
import { FOCUSABLE } from '~/utils/dom'
import useProductStore from '~/stores/product'
import { useMessagesStore } from '~/stores/messages'
import useLicenseControlStore from '~/stores/licenseControl'
import useGroupsStore from '~/stores/groups'
import { useAuthStore } from '~/stores/auth'
import { PreferredLanguage } from '~/models/User/PreferredLanguage'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import { useRouteMeta } from '~/composables/useRouteMeta'
import { useAppColor } from '~/composables/useAppColor'
import useAppBanner from '~/composables/banner'
import PreviewOverlay from '~/components/paywall/PreviewOverlay.vue'
import PaywallOverlay from '~/components/paywall/PaywallOverlay.vue'
import WizardDialog from '~/components/onboarding/WizardDialog.vue'
import DialogRenderer from '~/components/dialogs/DialogRenderer.vue'
import AppHeader from '~/components/AppHeader.vue'
import AppFooter from '~/components/AppFooter.vue'

const router = useRouter()
const { getProducts } = useProductStore()
const { userPreferredLanguage, userOrganization, isAuthenticated, hasLoadedUser, needsOrganization } = storeToRefs(useAuthStore())
const { loadGroups } = useGroupsStore()
const { getMessages } = useMessagesStore()
const { hasAccessRestricted } = storeToRefs(useLicenseControlStore())

const { locale, t } = useI18n()
const { banner } = useAppBanner()

const { hideAppFooter, hideAppHeader, hideWizard } = useRouteMeta()

const { bgColor } = useAppColor()

const focusFirstFocusableElement = () => {
  const appContent = document.querySelector('#app-content')
  if (!appContent) return
  const firstFocusableElement = appContent.querySelectorAll(FOCUSABLE).item(0)
  if (!firstFocusableElement) return
  (firstFocusableElement as HTMLElement).focus()
}

const appContentClasses = computed(() => [
  `bg-${bgColor.value?.name} before:content-[''] before:fixed before:top-0 before:-z-10 before:h-44 before:inset-x-0 before:bg-[inherit]`,
  ...(!hideAppFooter.value ? ['mb-12'] : []),
  ...(hasAccessRestricted.value ? ['max-h-screen', 'overflow-hidden'] : []),
])

const hasPreviewOverlay = computed(() => {
  const hasPreview = !!router.currentRoute.value?.meta?.preview
  return hasPreview && hasLoadedUser.value && !isAuthenticated.value
})

onMounted(() => {
  getMessages()
})

watch(userPreferredLanguage, (newPreferredLanguage) => {
  locale.value = newPreferredLanguage
  Settings.defaultLocale = userPreferredLanguage.value || PreferredLanguage.Bokmal
}, { immediate: true })

watch([hasLoadedUser, userOrganization], () => {
  if (!isAuthenticated.value) return
  if (needsOrganization.value) return
  getProducts()
  loadGroups()
})
</script>

<template>
  <a
    v-if="banner"
    href="#app-content"
    tabindex="1"
    class="fixed z-50 -translate-x-full -translate-y-full whitespace-pre-line rounded-lg bg-seagreen-40 p-2 font-medium text-white focus:translate-x-4 focus:translate-y-4 focus:ring focus:ring-offset-2"
    @click.prevent.stop="focusFirstFocusableElement"
    v-text="t('jumpToContent')"
  />
  <AppHeader v-if="!hideAppHeader" />
  <div :class="TeleportationTarget.AppTop.replace('.', '')" />
  <main
    id="app-content"
    class="mx-auto w-full grow"
    :class="appContentClasses"
  >
    <PreviewOverlay v-if="hasPreviewOverlay" />
    <PaywallOverlay v-else-if="hasAccessRestricted" />
    <router-view />
  </main>
  <AppFooter v-if="!hideAppFooter" />
  <WizardDialog v-if="!hideWizard" />
  <DialogRenderer />
  <div
    id="breakpointCheck"
    class="!h-0 max-h-0 min-h-0 w-0 xs:w-1 sm:w-2 md:w-3 lg:w-4 xl:w-5"
  />
</template>
