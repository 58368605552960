<script setup lang="ts">
import type { Organization } from '~/models/Organization'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton } from '@aschehoug/kloss'
import { useAuthStore } from '~/stores/auth'
import useOidc from '~/composables/useOidc'

const { t } = useI18n()
const authStore = useAuthStore()
const { user, userOrganization, userOrganizations, isTeacher } = storeToRefs(authStore)
const selectedOrganization = ref<Organization|undefined>(undefined)
const { logoutRedirect } = useOidc()

const roleAtOrganization = computed(() => {
  return t('settings.roleAtOrganization', { role: isTeacher.value ? t('settings.roleTeacher') : t('settings.roleStudent'), organization: selectedOrganization.value?.name })
})

onMounted(() => {
  selectedOrganization.value = userOrganizations.value
    .find(({ number }) => number === userOrganization.value?.number)
})
</script>

<template>
  <div class="flex flex-col gap-2 border-y border-gray-10 py-6">
    <div class="flex items-center justify-between">
      <div class="mr-4">
        <p
          class="text-xl font-medium"
          v-text="user?.fullName"
        />
        <p
          v-if="selectedOrganization"
          class="text-base"
          v-text="roleAtOrganization"
        />
      </div>
      <KsButton
        variant="secondary"
        size="small"
        shape="rounded"
        icon-left="sign-out"
        @click="logoutRedirect"
      >
        {{ t('logout') }}
      </KsButton>
    </div>
  </div>
</template>
