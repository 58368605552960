<script setup lang="ts" generic="T">
import type { FavoriteCreateInput, FavoriteList } from '~/models/Favorites'
import type { ContentFile } from '~/models/Content/ContentFile'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, defineAsyncComponent, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsPopover } from '@aschehoug/kloss'
import usePlannerStore from '~/stores/planner'
import useGroupsStore from '~/stores/groups'
import useDialogStore from '~/stores/dialog'
import { useAuthStore } from '~/stores/auth'
import { userInteraction } from '~/models/userInteractions'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useLanguageMap from '~/composables/useLanguageMap'
import { useFavorites } from '~/composables/useFavorites'
import useError from '~/composables/useError'
import { useContentHelper } from '~/composables/useContentHelper'
import ConditionalTooltip from '~/components/utils/ConditionalTooltip.vue'
import FavoriteDialog from '~/components/favorites/FavoriteDialog.vue'
import LearningPathDialog from '~/components/dialogs/LearningPathDialog.vue'
import CopyUrlButton from '~/components/cards/buttons/CopyUrlButton.vue'

const props = withDefaults(defineProps<{
  resource: T
  buttonVariant?: 'primary' | 'secondary' | 'tertiary' | 'border'
  buttonClasses?: string
  direction?: string
}>(),
{
  buttonVariant: 'secondary',
  direction: 'up left'
})

const toast = inject('ksToast') as Toast
const { t } = useI18n()
const { isTeacher, hasOrganization } = storeToRefs(useAuthStore())
const { hasGroups } = storeToRefs(useGroupsStore())
const { isUploadedFile, isPackage, isFile, isLearningPath } = useContentHelper()
const { buildResourceUrl, buildFileUrl } = useUrlBuilder()
const { addToWeek } = usePlannerStore()
const { showDialog } = useDialogStore()
const { getErrorMessage } = useError()
const { reverseMap } = useLanguageMap()
const { addItemToList } = useFavorites()

const showAddToWeekButton = computed(() => isTeacher.value && hasOrganization.value)
const showCopyUrlButton = computed(() => !isUploadedFile(props.resource as BaseItem) && !isPackage(props.resource as BaseItem))

const fileLanguageCodes = computed(() => {
  if (!isFile(props.resource as BaseItem)) return []
  return (props.resource as BaseItem).languageCodes.map((code) => (reverseMap[code]))
})

const addToAnotherWeek = async (resource: BaseItem) => {
  let userCanceled = false
  const weekNumber = await showDialog<number>(
    defineAsyncComponent(() => import('~/components/planner/WeekNumberDialog.vue')),
    { resource: resource },
  )
  .catch((e) => {
    if (e !== userInteraction.USER_CANCELED) throw e
  })

  if (!weekNumber) {
    return
  } else if (userCanceled) {
    return
  }

  try {
    await addToWeek(resource, weekNumber)
    toast.success(t('planner.addResource.success', { weekNumber }))
  } catch (error) {
    if (error !== userInteraction.USER_CANCELED) {
      toast.error(getErrorMessage(error))
      throw error
    }
  }
}
</script>
<template>
  <div>
    <KsPopover
      :title="t('resource')"
      :direction="direction"
      :autoposition="false"
    >
      <template #toggle>
        <slot name="toggle">
          <KsButton
            icon-left="ellipsis"
            shape="square"
            size="small"
            :variant="buttonVariant"
            :class="buttonClasses"
          />
        </slot>
      </template>
      <template #content>
        <slot
          name="menu"
          :item="resource"
        >
          <LearningPathDialog
            v-if="isLearningPath(resource as BaseItem)"
            :resource="resource as BaseItem"
            shape="normal"
            variant="tertiary"
            size="medium"
            :show-label="true"
          />
          <KsButton
            v-for="language in fileLanguageCodes"
            :key="`file-${language}`"
            stretch
            icon-left="download"
            :href="buildFileUrl(resource as ContentFile, language)"
          >
            {{ t('downloadIn', { in: t(`metadata.language.${language}`).toLowerCase() }) }}
          </KsButton>
          <ConditionalTooltip
            v-if="showAddToWeekButton"
            :condition="!hasGroups"
            :text="t('planner.addToWeek.noGroups')"
          >
            <KsButton
              stretch
              :disabled="!hasGroups"
              icon-left="archive"
              @click.capture.stop.prevent="addToAnotherWeek(resource as BaseItem)"
            >
              {{ t('planner.addToWeek.title') }}
            </KsButton>
          </ConditionalTooltip>
          <CopyUrlButton
            v-if="showCopyUrlButton"
            :url="buildResourceUrl(resource as BaseItem)"
            icon-left="copy"
            stretch
          />
          <slot name="favorite">
            <FavoriteDialog
              action="addTo"
              :item="resource"
            />
          </slot>
        </slot>
      </template>
    </KsPopover>
  </div>
</template>
