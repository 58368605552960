<script setup lang="ts">
import type { ContentSectionStandalone } from '~/models/Content/ContentSectionStandalone'
import { computed, defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuery } from '@tanstack/vue-query'
import { KsIcon } from '@aschehoug/kloss'
import { ContentType } from '~/models/Content/ContentType'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useSearchHelper from '~/composables/useSearchHelper'
import useContentApi from '~/api/contentApi'

const { findContents } = useContentApi()
const { t } = useI18n()
const { buildResourceUrl } = useUrlBuilder()
const { defaultContentTypes } = useSearchHelper()

const props = defineProps<{section: ContentSectionStandalone}>()

const { data: content } = useQuery({
  staleTime: Infinity,
  enabled: Number(props.section.resource?.destinationContentId) > 0,
  queryKey: ['content-id', props.section.contentId],
  queryFn: async () => (await findContents({
    contentIdCriterion: [Number(props.section.resource?.destinationContentId)],
    mainLocationCriterion: true,
    contentTypeCriterion: [...defaultContentTypes, ContentType.Product],
  }, 1))[0] ?? null
})

const url = computed(() => {
  if (!content.value) return ''
  return buildResourceUrl(content.value)
})

const subtitle = computed(() => {
  const subject = props.section.subjects[0] ? t(`metadata.subjects.${props.section.subjects[0]}`) : null
  const theme = content.value?.parentLocationName || null

  return [subject, theme].filter(Boolean).join(' | ')
})

const color = computed(() => props.section.colorPair.text.rgb)
const backgroundColor = computed(() => props.section.colorPair.background.rgb)
</script>

<template>
  <a
    :href="url"
    class="group grid h-full grid-cols-3 items-center overflow-hidden rounded-lg text-left ring-offset-2 ring-offset-seagreen-50 transition hover:ring hover:ring-seagreen-10 focus-visible:ring sm:col-span-6 md:min-h-64"
    :style="{ backgroundColor }"
  >
    <div
      class="col-span-full px-8 py-12 sm:col-span-2"
      :class="{ 'order-last': !section.alignRight }"
    >
      <p
        v-if="subtitle"
        class="mb-4 max-w-prose text-sm font-medium uppercase"
        :style="{ color }"
        v-text="subtitle"
      />
      <span
        class="max-w-prose text-2xl font-bold"
        :style="{ color }"
        v-text="section.title"
      />
      <p
        v-if="section.description"
        class="mt-2 max-w-prose"
        :style="{ color }"
        v-html="section.description"
      />
      <KsIcon
        icon="arrow-right-long"
        :scale="1.3"
        class="mt-4 transition-all group-hover:ml-2"
        :style="{ color }"
      />
    </div>
    <div class="col-span-full aspect-video size-full sm:col-span-1">
      <img
        class="size-full object-cover"
        :src="section.thumbnail"
        :alt="section.thumbnailAlt"
      >
    </div>
  </a>
</template>

<style scoped>
:deep(a) {
  color: v-bind(color);
  font-weight: 600;
}
:deep(a):hover {
  background: v-bind(color);
  color: v-bind(backgroundColor);
  transition: .1s;
  border-radius: 1px;
}
:deep(a):focus-visible {
  @apply ring
}
</style>
