<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton } from '@aschehoug/kloss'
import useBlogPosts from '~/composables/useBlogPosts'
import BlogPostSimpleCard from '~/components/cards/BlogPostSimpleCard.vue'

const props = defineProps<{ limit: number }>()

const { t } = useI18n()
const { loadPosts, matchesGrades } = useBlogPosts()
const { data: posts } = loadPosts(props.limit * 3)

const postsByGrades = computed(() => (posts?.value ?? []).filter(matchesGrades).slice(0, props.limit))
</script>

<template>
  <section
    v-if="postsByGrades.length"
    class="mx-auto w-full max-w-screen-au space-y-5 px-4 py-12 sm:px-8"
  >
    <h2
      class="text-xl font-bold text-gray-50"
      v-text="t('blog.title')"
    />
    <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <BlogPostSimpleCard
        v-for="post in postsByGrades"
        :key="`blog-post-${post.contentId}`"
        :blog-post="post"
      />
    </ul>
    <RouterLink
      :to="{ name: 'blog' }"
      class="inline-flex rounded-xl focus-visible:ring"
    >
      <KsButton
        variant="primary"
        type="label"
      >
        {{ t('blog.seeAllPosts') }}
      </KsButton>
    </RouterLink>
  </section>
</template>
