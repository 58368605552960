<script setup lang="ts">
import type { ContentFlashcardDeck } from '~/models/Content/ContentFlashcards'
import { KsSkeleton } from '@aschehoug/kloss'
import { useFlashcardsQuery } from '~/composables/useFlashcardsQuery'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import Carousel from '~/components/media/Carousel.vue'

const props = defineProps<{
  content: ContentFlashcardDeck
}>()

const { data, isLoading } = useFlashcardsQuery(props.content.locationId)
</script>

<template>
  <section
    :data-location-id="content.locationId"
    :data-content-type="content.contentTypeIdentifier"
    class="text-carousel"
  >
    <KsSkeleton
      v-if="isLoading"
      class="col-[1/-1] aspect-square !h-60"
      background="rgb(var(--color-light))"
    />

    <div v-if="data?.cards">
      <h1
        class="flex items-center justify-center p-4 text-4xl font-semibold"
        v-text="data?.location.title"
      />
      <Carousel
        :style="{
          '--carousel-spacing': '2rem',
          '--ks-border': 'var(--color-light)',
          '--ks-borderhoverfill': 'var(--color-light)',
          '--ks-borderhovertext': 'var(--color-darker)',
        }"
        nav="below"
        class="relative grid"
      >
        <template #default="{ index: activeIndex }">
          <div
            v-for="(card, index) in data?.cards"
            :key="card.locationId"
            class="grid w-[calc(var(--col-width)*6+var(--col-gap)*6+var(--text-width))] grid-rows-[minmax(0,1fr),max-content] justify-center"
          >
            <div
              class="flex w-[500px] items-center justify-center rounded-md bg-[--color-light] p-4 text-4xl font-semibold transition-opacity"
              :class="{ 'opacity-0': activeIndex !== index}"
            >
              <RichTextRenderer
                :text="card.bodyFront"
                class="p-6 text-center text-[--color-darker]"
              />
            </div>
          </div>
        </template>

        <template #counter="{ index }">
          <span
            class="px-4 text-2xl font-bold text-[--color-light]"
            v-text="`${index + 1} / ${data?.cards.length ?? 0}`"
          />
        </template>
      </Carousel>
    </div>
  </section>
</template>

<style scoped>
.text-carousel {
  & :deep(.carousel-nav-button) {
    background-color: var(--color-darker);
    color: var(--color-light);

    &:hover {
      --ks-border: var(--color-darker);
    }
  }
}
</style>
