<script setup lang="ts">
import type { Component } from 'vue'
import type { SubjectComponentProps } from '~/models/Subject'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'
import { GradeType } from '~/models/GradeType'
import SubjectNotImplemented from '~/components/subject/SubjectNotImplemented.vue'
import LowerSecondarySubject from '~/components/subject/gradetypes/LowerSecondarySubject.vue'
import LowerPrimarySubject from '~/components/subject/gradetypes/LowerPrimarySubject.vue'

defineProps<SubjectComponentProps>()

const { selectedGradeType } = storeToRefs(useAuthStore())

const gradeTypeComponentMap = new Map<GradeType, Component>([
  [GradeType.LowerPrimary, LowerPrimarySubject],
  [GradeType.UpperPrimary, LowerPrimarySubject],
  [GradeType.LowerSecondary, LowerSecondarySubject],
])

const componentToRender = computed(() => {
  const component = selectedGradeType.value ? gradeTypeComponentMap.get(selectedGradeType.value) : null
  return component || SubjectNotImplemented
})
</script>

<template>
  <component
    :is="componentToRender"
    v-if="componentToRender"
    :subject-code="subjectCode"
    :contents="contents"
    :is-upcoming="isUpcoming"
  />
</template>
