<script setup lang="ts">
import type { SearchResponse } from '~/models/Content/Response'
import type { ContentLink } from '~/models/Content/ContentLink'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useQuery } from '@tanstack/vue-query'
import { KsCallout, KsSkeleton } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import { useMessagesStore } from '~/stores/messages'
import { Subtree } from '~/models/Content/Subtree'
import { ContentType } from '~/models/Content/ContentType'
import useSearchHelper from '~/composables/useSearchHelper'
import useSearchClient from '~/composables/useSearchClient'
import useContentMapper from '~/composables/useContentMapper'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import AuLogo from '~/components/utils/AuLogo.vue'
import CardSkeleton from '~/components/skeletons/CardSkeleton.vue'
import LoginButtons from '~/components/login/LoginButtons.vue'
import FlowHeaderBorder from '~/components/login/FlowHeaderBorder.vue'
import ArticleCard from '~/components/cards/ArticleCard.vue'

const router = useRouter()
const { t } = useI18n()
const { hasLoginMessage, messages } = useMessagesStore()
const { mapContents } = useContentMapper()
const { contentPath, emptyQuery, cardFields } = useSearchHelper()

const redirectTo = computed(() => router.currentRoute.value.query?.['tilbake-til'] ?? '/')

const transformData = (response: SearchResponse) => mapContents(response.View.Result)
const { fetchResults, results } = useSearchClient<Array<ContentLink>>(contentPath, { transformData })

const { data, isLoading } = useQuery({
  queryKey: ['anonymous-news'],
  queryFn: async () => {
    await fetchResults({ ...emptyQuery, fields: [...cardFields, ...['intro']] }, {
      subtreeCriterion: [Subtree.Login],
      contentTypeCriterion: [ContentType.Link],
      sortField: 'priority',
      sortOrder: 'asc',
    }, 4)

    return { articles: results.value }
  }
})

setTitle(t('login.title'))
</script>

<template>
  <h1
    class="sr-only"
    v-text="t('login.brand')"
  />
  <div class="flex w-full flex-col items-center bg-[#165851]">
    <AuLogo class="mx-auto h-24 w-auto pt-8 text-white" />
    <img
      src="../assets/images/Aunivers.png"
      class="mx-auto w-[400px] pb-16 sm:w-[600px] sm:pb-0"
      alt=""
    >
  </div>
  <div class="relative -mt-1">
    <FlowHeaderBorder />
    <div class="absolute -top-20 mb-12 flex w-full flex-col sm:absolute sm:-top-6 xl:top-0">
      <p
        class="z-20 mb-3 text-center font-bold text-white fluid-text-lg"
        v-text="t('login.title')"
      />
      <div class="z-20 flex justify-center gap-4">
        <LoginButtons :redirect-to="redirectTo" />
      </div>
    </div>
  </div>
  <div class="mx-auto max-w-screen-au px-4 pt-8 sm:px-8">
    <KsCallout
      v-if="hasLoginMessage"
      :title="messages?.loginMessageTitle"
      variant="warning"
      class="z-10 mx-auto mb-8 max-w-2xl"
    >
      <RichTextRenderer :text="messages?.loginMessage" />
    </KsCallout>
    <div
      v-if="isLoading"
      class="text-center"
    >
      <KsSkeleton
        width="120px"
        height="25px"
        class="mb-6"
      />
      <div class="grid grid-cols-1 gap-6 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <CardSkeleton
          v-for="i in 4"
          :key="i"
          height="370px"
        />
      </div>
    </div>
    <div v-else-if="data?.articles?.length">
      <h2
        class="mb-6 text-center font-bold fluid-text-lg"
        v-text="t('login.news')"
      />
      <ul class="grid grid-cols-1 gap-6 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <li
          v-for="article in data.articles"
          :key="article.contentId"
        >
          <ArticleCard :article="article" />
        </li>
      </ul>
    </div>
  </div>
</template>
