import type { CookieAttributes } from 'js-cookie'
import { UserManager } from 'oidc-client-ts'
import Cookies from 'js-cookie'

export const cookieName = <string>import.meta.env.VITE_SESSION_COOKIE_NAME

export const cookieConfig: CookieAttributes = {
  domain: <string>import.meta.env.VITE_SESSION_COOKIE_DOMAIN,
  sameSite: <'strict'|'lax'|'none'>import.meta.env.VITE_SESSION_COOKIE_SAMESITE,
  secure: true,
}

export const getAccessToken = (): string => Cookies.get(cookieName) ?? ''

export const manager = new UserManager({
  automaticSilentRenew: false,
  authority: `${import.meta.env.VITE_AM_URL}/oauth2/realms/root`,
  client_id: import.meta.env.VITE_OIDC_CLIENT_ID,
  scope: import.meta.env.VITE_OIDC_SCOPE,
  redirect_uri: import.meta.env.VITE_OIDC_CALLBACK_URL,
  response_type: import.meta.env.VITE_OIDC_RESPONSE_TYPE,
  post_logout_redirect_uri: `https://${import.meta.env.VITE_HOST}`,
  metadata: {
    authorization_endpoint: `${import.meta.env.VITE_AM_URL}/oauth2/authorize`,
    userinfo_endpoint: `${import.meta.env.VITE_AM_URL}/oauth2/userinfo`,
    token_endpoint: `${import.meta.env.VITE_AM_URL}/oauth2/access_token`,
    end_session_endpoint: `${import.meta.env.VITE_AM_URL}/oauth2/connect/endSession`,
  }
})
