<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { Product, SupportProductName } from '~/models/Product'
import { storeToRefs } from 'pinia'
import { KsButton, KsDialog } from '@aschehoug/kloss'
import useProductStore from '~/stores/product'
import useAuthStore from '~/stores/auth'
import TeleportationTarget from '~/models/TeleportationTarget'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useText from '~/composables/useText'
import useSubjectButton from '~/composables/useSubjectButton'
import useProductHelper from '~/composables/useProductHelper'

const props = defineProps<{
  name: string
  products: Product[]
  subjects: SubjectCode[]
}>()

const { resolveBaseUrl } = useUrlBuilder()
const { capitalize } =  useText()
const { getStyles } = useSubjectButton()
const { isLowerPrimaryUser } = storeToRefs(useAuthStore())

const { isSupportName } = useProductHelper()
const { selectedSupportProductName } = storeToRefs(useProductStore())

const selectedSupportProducts = (selected: SupportProductName) => {
  return props.products.filter((product) => isSupportName(product, selected))
}

const emit = defineEmits(['close'])
</script>

<template>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      :title="capitalize(name)"
      :open="selectedSupportProductName === name"
      @close="emit('close')"
    >
      <template #body>
        <ul
          v-if="products.length"
          class="grid flex-col gap-2"
        >
          <li
            v-for="(product, index) in selectedSupportProducts(name)"
            :key="product.ean"
          >
            <RouterLink
              :to="{ path: resolveBaseUrl(product) }"
              class="inline-flex w-full rounded-xl transition focus-visible:ring"
            >
              <KsButton
                variant="primary"
                type="label"
                stretch
                center
                :class="{'subject-button': isLowerPrimaryUser}"
                class="!text-2xl !font-bold"
                :style="isLowerPrimaryUser ? getStyles(subjects.length + index): {}"
              >
                {{ product.aunivers.name }}
              </KsButton>
            </RouterLink>
          </li>
        </ul>
      </template>
    </KsDialog>
  </Teleport>
</template>

<style scoped>
.subject-button {
  --ks-primary: #002422;
  padding: 1.2em 1em;
}
.subject-button:hover {
  --ks-primarytext: #002422!important;
}
</style>
