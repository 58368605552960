<script setup lang="ts">
import type { HeaderComponentProps, HeaderViewProps } from '~/models/Subject'
import type { ContentPackage } from '~/models/Content/ContentPackage'
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import { waitFor } from '~/utils/asyncUtils'
import arrayUtils from '~/utils/arrayUtils'
import useSubjectsStore from '~/stores/subjects'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import useAuthStore from '~/stores/auth'
import { GradeType } from '~/models/GradeType'
import { MaxTimeoutMs } from '~/constants/api'
import useQueryFilter from '~/composables/useQueryFilter'
import { useContentHelper } from '~/composables/useContentHelper'
import { useContentFilter } from '~/composables/useContentFilter'
import { useAppColor } from '~/composables/useAppColor'
import useContentApi from '~/api/contentApi'
import HeaderViewBannerGrid from '~/components/utils/HeaderViewBannerGrid.vue'
import LowerSecondaryHeader from '~/components/subject/gradetypes/LowerSecondaryHeader.vue'
import LowerPrimaryHeader from '~/components/subject/gradetypes/LowerPrimaryHeader.vue'
import ContentFilteredAwayCallout from '~/components/ResourceFinder/ContentFilteredAwayCallout.vue'

const props = defineProps<HeaderViewProps>()

const filterStore = useFilterStore()
const router = useRouter()
const { selectedGradeType } = storeToRefs(useAuthStore())
const { isLoading: filterIsLoading, selectedHeaders, selectedGrade } = storeToRefs(useFilterStore())
const { filteredContents, filteredProductPackages, contents } = useContentFilter()
const { isPackage, isContentHeader, packageTypes } = useContentHelper()
const { filterWatchers, setSelectedHeader } = filterStore
const { setCurrentSubject } = useSubjectsStore()
const { findContents } = useContentApi()
const { truthy } = arrayUtils()

const productStore = useProductStore()
const { getRelatedLocationsByGradeAndSubject } = productStore
const { hasLoaded: hasLoadedProducts } = storeToRefs(productStore)
const { clear: clearAppColor } = useAppColor()

const contentItems = computed(() => filteredContents.value.filter((content) => !isPackage(content)))
const combinedFiltered = computed(() => [...contentItems.value, ...filteredProductPackages.value])
const combinedContents = computed(() => [...contents.value, ...filteredProductPackages.value])

const headerProps = computed((): HeaderComponentProps => ({
  contents: contentItems.value,
  packages: filteredProductPackages.value,
  header: selectedHeaders.value[0],
  headerKey: isUpcoming.value ? 'upcomingContent' : 'learningPath',
  subjectRoute: isUpcoming.value ? 'subject_upcoming' : 'subject',
}))

const watchers = filterWatchers()

watch(props, () => {
  setCurrentSubject(props.subjectCode)
})

onMounted(() => {
  setCurrentSubject(props.subjectCode)
  useQueryFilter()
})

const isUpcoming = ref(false)

const { data: content } = useQuery({
  staleTime: Infinity,
  queryKey: ['header-content', props.headerLocationId],
  queryFn: () => findContents<ContentPackage>({
    locationIdCriterion: [props.headerLocationId],
    contentTypeCriterion: packageTypes,
  }).then(([data]) => data)
})

watch(content, async () => {
  if (!content.value) return
  setTitle(content.value.title)
  await waitFor(() => hasLoadedProducts.value, MaxTimeoutMs)
  const { upcomingLocationIds } = await getRelatedLocationsByGradeAndSubject(selectedGrade.value, props.subjectCode)
  isUpcoming.value = upcomingLocationIds.filter(truthy).some((u) => content.value.pathString.includes(`/${u}/`))
}, { immediate: true })

watch(content, async () => {
  if (!content.value) return
  await waitFor(() => hasLoadedProducts.value, MaxTimeoutMs)
  if (selectedHeaders.value.length === 0 && isContentHeader(content.value)) {
    setSelectedHeader([content.value])
  }
}, { immediate: true })

onBeforeUnmount(() => {
  watchers.stopFilterWatchers()
  setCurrentSubject(undefined)
  clearAppColor()
})

watch(selectedGrade, (grade) => {
  if (headerProps.value.header?.grades.includes(grade)) return

  nextTick(() => {
    router.push({
      name: 'subject',
      params: {
        subject: props.subjectCode.toLowerCase()
      }
    })
  })
})
</script>

<template>
  <div
    v-if="!combinedFiltered.length && combinedContents.length > 0 && !filterIsLoading"
    class="mx-auto flex max-w-screen-au flex-col gap-4 px-4 pb-16 pt-36 sm:px-8"
  >
    <ContentFilteredAwayCallout :contents="combinedContents" />
  </div>

  <div v-else-if="!headerProps.header || filterIsLoading">
    <KsSkeletonWrapper>
      <HeaderViewBannerGrid class="py-24">
        <div class="flex gap-2 grid-in-filters">
          <KsSkeleton
            height="2rem"
            width="8rem"
          />
          <KsSkeleton
            height="2rem"
            width="8rem"
          />
        </div>
        <div class="grid-in-header-info">
          <KsSkeleton height="4rem" />
          <KsSkeleton
            height="1.5rem"
            width="80%"
          />
          <KsSkeleton
            height="1.5rem"
            width="70%"
          />
          <KsSkeleton
            height="1.5rem"
            width="80%"
          />
          <KsSkeleton
            height="1.5rem"
            width="65%"
          />
          <KsSkeleton
            height="1.5rem"
            width="70%"
          />
        </div>
      </HeaderViewBannerGrid>
    </KsSkeletonWrapper>

    <!-- these sections are a copy of the skeleton in LowerPrimaryHeader -->
    <section
      v-for="index in 2"
      :key="index"
    >
      <KsSkeletonWrapper class="mx-auto max-w-screen-au space-y-4 px-4 py-8 sm:px-8">
        <KsSkeleton
          height="36px"
          width="120px"
        />
        <ul class="grid grid-cols-1 gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <KsSkeleton
            v-for="i in 4"
            :key="i"
            height="280px"
          />
        </ul>
      </KsSkeletonWrapper>
    </section>
  </div>

  <template v-else>
    <LowerPrimaryHeader
      v-if="selectedGradeType === GradeType.LowerPrimary && combinedFiltered.length > 0"
      v-bind="headerProps"
    />

    <LowerPrimaryHeader
      v-if="selectedGradeType === GradeType.UpperPrimary && combinedFiltered.length > 0"
      v-bind="headerProps"
    />

    <LowerSecondaryHeader
      v-if="selectedGradeType === GradeType.LowerSecondary && combinedFiltered.length > 0"
      v-bind="headerProps"
    />
  </template>
</template>
