<script lang="ts" setup>
import type { SubjectCode, SubjectComponentProps } from '~/models/Subject'
import { computed, onBeforeUnmount, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { setTitle } from '~/utils/dom'
import useSubjectsStore from '~/stores/subjects'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import useQueryFilter from '~/composables/useQueryFilter'
import { useContentFilter } from '~/composables/useContentFilter'
import { useAppColor } from '~/composables/useAppColor'
import SubjectComponentView from '~/components/subject/SubjectComponentView.vue'

const props = defineProps<{ subjectCode: SubjectCode }>()

const { t } = useI18n()
const filterStore = useFilterStore()
const { filterWatchers, $reset } = filterStore
const { hasLoaded } = storeToRefs(useProductStore())
const { setCurrentSubject } = useSubjectsStore()
const { filteredContents }  = useContentFilter(true, true, 'upcomingContent')
const { clear: clearAppColor } = useAppColor()

setTitle(t(`metadata.subjects.${props.subjectCode}`))

const watchers = filterWatchers()

onMounted(() => useQueryFilter())

onBeforeUnmount(() => {
  watchers.stopFilterWatchers()
  setCurrentSubject(undefined)
  clearAppColor()
  $reset()
})

watch(props, async () => {
  setCurrentSubject(props.subjectCode)
}, { immediate: true })

const componentProps = computed((): SubjectComponentProps => ({
  subjectCode: props.subjectCode,
  contents: filteredContents.value,
  isUpcoming: true,
}))
</script>

<template>
  <SubjectComponentView
    v-if="hasLoaded"
    v-bind="componentProps"
  />
</template>
