<script setup lang="ts">
import type { FiltersQueryParams } from '~/models/Filter'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton } from '@aschehoug/kloss'
import { waitFor } from '~/utils/asyncUtils'
import arrayUtils from '~/utils/arrayUtils'
import useSubjectsStore from '~/stores/subjects'
import useProductStore from '~/stores/product'
import useFilterSettingsStore from '~/stores/filterSettings'
import useFilterStore from '~/stores/filter'
import { QueryParamKey } from '~/models/QueryParamKeys'
import { MaxTimeoutMs } from '~/constants/api'
import useQueryParams from '~/composables/queryParams'
import UpcomingSubjectButton from '~/components/ResourceFinder/UpcomingSubjectButton.vue'
import TaskTypeFilter from '~/components/ResourceFinder/TaskTypeFilter.vue'
import SubjectFilter from '~/components/ResourceFinder/SubjectFilter.vue'
import ProductFilter from '~/components/ResourceFinder/ProductFilter.vue'
import HeaderFilter from '~/components/ResourceFinder/HeaderFilter.vue'
import GradeFilter from '~/components/ResourceFinder/GradeFilter.vue'
import ColophonButton from '~/components/ResourceFinder/ColophonButton.vue'

const props = withDefaults(defineProps<{
  autoSelect?: boolean
  enableSubjectFilter?: boolean
  enableGradeFilter?: boolean
}>(), {
  autoSelect: false,
  enableSubjectFilter: true,
  enableGradeFilter: true,
})

const { t } = useI18n()
const filterStore = useFilterStore()
const {
  selectedHeaderChildren,
  selectedHeaders,
} = storeToRefs(filterStore)
const { resetViewMode } = filterStore
const { filterOptions } = storeToRefs(useFilterSettingsStore())
const { clear } = useQueryParams<FiltersQueryParams>(QueryParamKey.Filters)
const { hasLoaded: hasLoadedProducts } = storeToRefs(useProductStore())
const { getRelatedLocationsByGradeAndSubject } = useProductStore()
const { truthy } = arrayUtils()

const { currentSubject } = storeToRefs(useSubjectsStore())
const { selectedGrade } = storeToRefs(useFilterStore())

const clearFilter = () => {
  resetViewMode()
  clear()
}

const hasUpcomingContent = ref(false)

watch([selectedGrade, currentSubject], async () => {
  await waitFor(() => hasLoadedProducts.value, MaxTimeoutMs)
  if (!(selectedGrade.value && currentSubject.value)) return
  const { upcomingLocationIds } = await getRelatedLocationsByGradeAndSubject(selectedGrade.value, currentSubject.value)
  hasUpcomingContent.value = upcomingLocationIds.filter(truthy).length > 0
}, { immediate: true })
</script>

<template>
  <header
    id="resource-finder"
    class="relative mx-auto my-8 flex max-w-screen-au flex-col flex-wrap gap-4 px-4 transition-all sm:flex-row sm:items-center sm:px-8"
  >
    <SubjectFilter
      v-if="enableSubjectFilter"
      v-show="filterOptions.subject"
    />
    <GradeFilter
      v-if="enableGradeFilter"
      v-show="filterOptions.grade"
    />
    <ProductFilter v-show="filterOptions.product" />
    <HeaderFilter
      v-show="filterOptions.header"
      :index="0"
      :enable-grade-filter="enableGradeFilter"
      resettable
    />
    <HeaderFilter
      v-for="(headerChildren, index) in selectedHeaderChildren"
      v-show="filterOptions.header"
      :key="headerChildren[0]?.locationId"
      :index="index + 1"
      :resources="headerChildren"
      :auto-select="props.autoSelect"
      :enable-grade-filter="enableGradeFilter"
      resettable
    />
    <TaskTypeFilter v-show="filterOptions.taskType" />
    <KsButton
      v-if="selectedHeaders.length > 0"
      variant="tertiary"
      shape="rounded"
      @click="clearFilter"
    >
      {{ t('filters.emptyFilter') }}
    </KsButton>

    <div class="ml-auto grid auto-cols-max grid-flow-col gap-4">
      <UpcomingSubjectButton
        v-if="hasUpcomingContent && currentSubject"
        :subject-code="currentSubject"
      />
      <ColophonButton v-if="hasLoadedProducts" />
    </div>
  </header>
</template>
