<script setup lang="ts">
import type { ContentPackage } from '~/models/Content/ContentPackage'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, watch } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import { colors, forceColorShade, getColorForId } from '~/utils/colors'
import useProductStore from '~/stores/product'
import useLicenseControlStore from '~/stores/licenseControl'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useSearchHelper from '~/composables/useSearchHelper'
import { useContentHelper } from '~/composables/useContentHelper'
import useContentApi from '~/api/contentApi'
import CloseButton from '~/components/utils/CloseButton.vue'
import ProductPackageIntro from '~/components/ResourceFinder/ProductPackageIntro.vue'
import NotFound from '~/components/ResourceEngine/Components/NotFound.vue'
import StackedCard from '~/components/cards/StackedCard.vue'
import SearchCard from '~/components/cards/SearchCard.vue'
import BookCard from '~/components/cards/BookCard.vue'

const props = defineProps<{ locationId: number }>()

const { findContents } = useContentApi()
const { packageTypes } = useContentHelper()
const { cardFields } = useSearchHelper()
const { getProductFromResource } = useProductStore()
const { resolveBaseUrl } = useUrlBuilder()
const { isPackage, isProduct, isBookReader } = useContentHelper()
const { checkItemLicense } = useLicenseControlStore()

const { data, isLoading, isError } = useQuery({
  queryKey: computed(() => ['package', props.locationId]),
  queryFn: async () => {
    const packageData = (await findContents<ContentPackage>({
      locationIdCriterion: [props.locationId],
      contentTypeCriterion: packageTypes,
    }, 1))[0]

    const children = await findContents<BaseItem>({
      parentLocationIdCriterion: [packageData.mainLocationId],
      sortField: packageData.sortField,
      sortOrder: packageData.sortOrder,
    }, 100, 0, cardFields)

    return { packageData, children }
  },
  enabled: computed(() => !!props.locationId),
  staleTime: Infinity,
})

const product = computed(() => data.value && getProductFromResource(data.value.packageData))

const isPackageCard = (resource: BaseItem) => isPackage(resource) || isProduct(resource)
const isBookCard = (resource: BaseItem) => isBookReader(resource)

watch(data, () => {
  if (!data.value) return
  setTitle(data.value.packageData.title)
  checkItemLicense(data.value.packageData)
}, { immediate: true })
</script>

<template>
  <NotFound v-if="isError" />
  <template v-else>
    <KsSkeletonWrapper
      v-if="isLoading"
      class="mb-12"
    >
      <div class="mb-8 w-full bg-gray-5/50 py-8">
        <div class="mx-auto max-w-screen-au space-x-4 px-4 pb-8 sm:px-8">
          <KsSkeleton
            v-for="i in 3"
            :key="i"
            width="120px"
            height="30px"
          />
        </div>
        <div class="mx-auto grid max-w-screen-au grid-cols-1 items-center gap-8 px-4 sm:px-8 md:grid-cols-2">
          <KsSkeleton
            height="400px"
            width="100%"
          />
          <div>
            <KsSkeleton
              height="40px"
              width="100%"
              class="mb-4"
            />
            <KsSkeleton
              v-for="i in 4"
              :key="i"
              height="20px"
              width="100%"
            />
          </div>
        </div>
      </div>
      <div class="mx-auto grid max-w-screen-au auto-rows-fr grid-cols-1 gap-4 px-4 xs:grid-cols-2 sm:px-8 md:grid-cols-3">
        <KsSkeleton
          v-for="i in 16"
          :key="i"
          height="160px"
        />
      </div>
    </KsSkeletonWrapper>
    <div
      v-else
      class="mb-12"
    >
      <CloseButton
        :item="data?.packageData"
        :override="product && resolveBaseUrl(product)"
        style="--ks-border: rgb(var(--au-gray-50));"
      />
      <ProductPackageIntro
        v-if="data"
        :content="data.packageData"
      />
      <ul
        v-if="data"
        class="mx-auto grid max-w-screen-au auto-rows-fr grid-cols-1 gap-4 px-4 xs:grid-cols-2 sm:px-8 md:grid-cols-3"
      >
        <li
          v-for="item in data.children"
          :key="`resource-${item.locationId}`"
          class="row-span-1 animate-fade"
          :class="{
            'row-span-2': isPackageCard(item) && item.thumbnail,
            'row-span-4': isBookCard(item),
          }"
        >
          <StackedCard
            v-if="isPackageCard(item)"
            :item
            class="size-full"
            :depth="3"
            element="div"
            :stack-offset="3"
            padded-stack
            :colors="[forceColorShade(getColorForId(item.locationId, colors), '10')]"
          >
            <template #default="{ item: searchItem }">
              <SearchCard
                v-if="searchItem"
                :item="searchItem"
                :show-path="false"
                package-style
              />
            </template>
          </StackedCard>
          <BookCard
            v-else-if="isBookCard(item)"
            :item
            class="!flex flex-col hover:bg-purple-10"
          />
          <SearchCard
            v-else
            :item
            :show-path="false"
          />
        </li>
      </ul>
    </div>
  </template>
</template>
