<script setup lang="ts">
import type { ContentSectionStandalone } from '~/models/Content/ContentSectionStandalone'
import { computed, defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuery } from '@tanstack/vue-query'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useSearchHelper from '~/composables/useSearchHelper'
import useContentApi from '~/api/contentApi'

const { findContents } = useContentApi()
const { t } = useI18n()
const { buildResourceUrl } = useUrlBuilder()
const { defaultContentTypes } = useSearchHelper()

const props = defineProps<{section: ContentSectionStandalone}>()

const { data: content } = useQuery({
  staleTime: Infinity,
  enabled: Number(props.section.resource?.destinationContentId) > 0,
  queryKey: ['content-id', props.section.contentId],
  queryFn: async () => (await findContents({
    contentIdCriterion: [Number(props.section.resource?.destinationContentId)],
    mainLocationCriterion: true,
    contentTypeCriterion: defaultContentTypes,
    }, 1))[0] ?? null
})

const url = computed(() => {
  if (!content.value) return ''
  return buildResourceUrl(content.value)
})

const subtitle = computed(() => {
  const subject = props.section.subjects[0] ? t(`metadata.subjects.${props.section.subjects[0]}`) : null
  const theme = content.value?.parentLocationName || null

  return [subject, theme].filter(Boolean).join(' | ')
})

const backgroundColor = computed(() => props.section.colorPair.background.rgb)
const color = computed(() => props.section.colorPair.text.rgb)

</script>

<template>
  <RouterLink
    :to="url"
    class="grid size-full items-center overflow-hidden rounded-lg text-center ring-offset-2 ring-offset-seagreen-50 transition hover:ring hover:ring-seagreen-10 focus-visible:ring md:min-h-64"
    :style="{ backgroundColor }"
    :class="{
      'grid-cols-1': !section.thumbnail,
      'grid-cols-1 sm:grid-cols-2': section.thumbnail,
    }"
  >
    <div
      class="flex size-full flex-col justify-center px-3 py-8"
      :class="{ 'order-last': section.alignRight }"
    >
      <p
        class="pb-4 text-xs font-medium uppercase md:text-sm"
        :style="{ color }"
        v-text="subtitle"
      />
      <span
        class="font-bold !leading-snug text-seagreen-50 fluid-text-2xl"
        :style="{ color }"
        v-text="section.title"
      />
    </div>
    <div
      v-if="section.thumbnail"
      class="aspect-video size-full sm:aspect-[3/2]"
    >
      <img
        class="size-full object-cover"
        :src="section.thumbnail"
        :alt="section.thumbnailAlt"
      >
    </div>
  </RouterLink>
</template>
