<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton } from '@aschehoug/kloss'
import arrayUtils from '~/utils/arrayUtils'
import useSubjectsStore from '~/stores/subjects'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import useContentApi from '~/api/contentApi'

const { t } = useI18n()
const router = useRouter()
const { truthy } = arrayUtils()
const { findContents } = useContentApi()

const { currentSubject } = storeToRefs(useSubjectsStore())
const { selectedGrade, selectedAddonLocationId, productHeaders } = storeToRefs(useFilterStore())
const { getRelatedLocationsByGradeAndSubject, getRelatedLocationsByLocationId } = useProductStore()

const locationIds = ref<number[]>([])

const showButton = computed(() => (productHeaders.value.colophons || []).length > 0)

const loadColophons = async () => {
  if (!locationIds.value.length) return
  try {
    productHeaders.value.colophons = (await findContents<BaseItem>({
      locationIdCriterion: locationIds.value,
      sortField: 'priority',
      sortOrder: 'asc'
    }))
  } catch (error) {
    productHeaders.value.colophons = []
    throw error
  }
}

watch([selectedGrade, currentSubject], async () => {
  if (!(selectedGrade.value && currentSubject.value)) return
  const { colophonLocationIds } = await getRelatedLocationsByGradeAndSubject(selectedGrade.value, currentSubject.value)
  locationIds.value = [...colophonLocationIds].filter(truthy<number>)
  await loadColophons()
}, { immediate: true })

watch(selectedAddonLocationId, async () => {
  if (!selectedAddonLocationId.value) return
  const { colophonLocationIds } = await getRelatedLocationsByLocationId(selectedAddonLocationId.value)
  locationIds.value = [...colophonLocationIds].filter(truthy<number>)
  await loadColophons()
}, { immediate: true })
</script>
<template>
  <KsButton
    v-if="showButton && currentSubject && selectedGrade"
    variant="secondary"
    icon-left="info"
    shape="round"
    @click.prevent="router.push({ name: 'colophon_subject', params: {
      subject: currentSubject.toLowerCase(),
      grade: selectedGrade.toLowerCase(),
    } })"
  />
  <KsButton
    v-if="showButton && selectedAddonLocationId && productHeaders.colophons"
    variant="secondary"
    icon-left="info"
    shape="round"
    @click.prevent="router.push({ name: 'colophon_location', params: {
      locationId: productHeaders.colophons[0].locationId
    }})"
  />
</template>
