import type { MaybeRefOrGetter } from 'vue'
import type { Media } from '~/models/Content/Media'
import { computed, isRef, toValue, watch } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { Subtree } from '~/models/Content/Subtree'
import useContentApi from '~/api/contentApi'

export function useMedia(contentIds: MaybeRefOrGetter<number[]>) {
  const { findContents } = useContentApi()
  const query = useQuery({
    initialData: [],
    enabled: computed(() => toValue(contentIds).some((n) => n > 0)),
    queryKey: ['use-media', contentIds],
    queryFn: async (): Promise<Media[]> => {
      try {
        return (await findContents<Media>({
          contentIdCriterion: toValue(contentIds),
          subtreeCriterion: [Subtree.Content, Subtree.Media],
          mainLocationCriterion: true,
        }))
      }

      catch {
        return []
      }
    }
  })

  if (isRef(contentIds)) {
    watch(contentIds, () => query.refetch())
  }

  return query
}
