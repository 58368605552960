import type { SubjectCode } from '~/models/Subject'
import type { Criterion } from '~/models/Search'
import type { ProductHeaders } from '~/models/ProductHeaders'
import type { GradeCode } from '~/models/Grade'
import type { SortField, SortOrder } from '~/models/Content/Sort'
import type { SearchResponse } from '~/models/Content/Response'
import type { ContentSvg } from '~/models/Content/ContentSvg'
import type { ContentImage } from '~/models/Content/ContentImage'
import type { BaseItem } from '~/models/Content/BaseItem'
import { storeToRefs } from 'pinia'
import { waitFor } from '~/utils/asyncUtils'
import arrayUtils from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import { useAuthStore } from '~/stores/auth'
import { Subtree } from '~/models/Content/Subtree'
import { ContentType } from '~/models/Content/ContentType'
import useSearchHelper from '~/composables/useSearchHelper'
import { useSearchClient } from '~/composables/useSearchClient'
import useContentMapper from '~/composables/useContentMapper'
import { contentClient } from '~/api/client/contentClient'

export default function useContentApi() {
  const { mapContents } = useContentMapper()
  const { searchPath, emptyQuery, cardFields } = useSearchHelper()
  const { truthy } = arrayUtils()
  const { userPreferredLanguage } = storeToRefs(useAuthStore())

  const defaultProductHeaders = { colophons: [], forTeacher: [], upcomingContent: [], flexibleContent: [], learningPath: [] }

  const getProductHeadersBySubjectAndGrade = async (subject: SubjectCode, grade: GradeCode): Promise<ProductHeaders> => {
    if (!subject || !grade) throw new Error()

    const productStore = useProductStore()
    const { getRelatedLocationsByGradeAndSubject } = productStore

    const {
      flexibleContentLocationIds,
      learningPathsLocationIds,
      interdisciplinaryContentLocationIds,
      upcomingLocationIds,
    } = await getRelatedLocationsByGradeAndSubject(grade, subject)

    const parentLocationIds = [...new Set([
      ...flexibleContentLocationIds,
      ...learningPathsLocationIds,
      ...interdisciplinaryContentLocationIds,
      ...upcomingLocationIds,
    ])].filter(truthy<number>)

    if (!parentLocationIds.length) {
      console.warn(`Missing parentLocationIds for ${subject} and ${grade}`)
      return defaultProductHeaders
    }

    const contents = await findContents<BaseItem>({
      parentLocationIdCriterion: parentLocationIds,
      gradeFieldCriterion: [grade],
      sortField: 'priority',
      sortOrder: 'asc',
    }, 100, 0, cardFields)

    const flexibleContent = contents
      .filter(({ pathString }) => flexibleContentLocationIds.some((locationId) => pathString.includes(`/${locationId}/`)))
    const learningPath = contents
      .filter(({ pathString }) => learningPathsLocationIds.some((locationId) => pathString.includes(`/${locationId}/`)))
    const upcomingContent = contents
      .filter(({ pathString }) => upcomingLocationIds.some((locationId) => pathString.includes(`/${locationId}/`)))

    return { ...defaultProductHeaders, ... { flexibleContent, learningPath, upcomingContent } }
  }

  const getProductHeadersByLocationId = async (locationId: number): Promise<ProductHeaders> => {
    if (!locationId) throw new Error()

    const productStore = useProductStore()
    const { hasProducts, isLoading, hasLoaded } = storeToRefs(productStore)
    const { getRelatedLocationsByLocationId } = productStore
    const { truthy } = arrayUtils()

    if (!hasProducts.value && !isLoading.value) {
      return new Promise((resolve) => {
        setTimeout(async () => {
          await productStore.getProducts()
          resolve(await getProductHeadersByLocationId(locationId))
        }, 100)
      })
    }

    await waitFor(() => hasLoaded.value, 5000)

    const {
      flexibleContentLocationIds,
      learningPathsLocationIds,
      interdisciplinaryContentLocationIds,
    } = await getRelatedLocationsByLocationId(locationId)

    const parentLocationIds = [...new Set([
      ...flexibleContentLocationIds,
      ...learningPathsLocationIds,
      ...interdisciplinaryContentLocationIds,
    ])].filter(truthy<number>)

    if (!parentLocationIds.length) {
      console.warn(`Missing parentLocationIds for ${locationId}`)
      return defaultProductHeaders
    }

    const contents = await findContents<BaseItem>({
      parentLocationIdCriterion: parentLocationIds,
      sortField: 'priority',
      sortOrder: 'asc',
    }, 100, 0, cardFields)

    const flexibleContent = contents
      .filter(({ pathString }) => flexibleContentLocationIds.some((locationId) => pathString.includes(`/${locationId}/`)))
    const learningPath = contents
      .filter(({ pathString }) => learningPathsLocationIds.some((locationId) => pathString.includes(`/${locationId}/`)))

    return { ...defaultProductHeaders, ...{ flexibleContent, learningPath } }
  }

  const findContents = async <T extends BaseItem>(
    criterions: Criterion,
    limit: number = 100,
    offset: number = 0,
    fields: string[] = [],
  ): Promise<T[]> => {
    const transformData = (response: SearchResponse) => mapContents(response.View.Result)
    const { fetchResults, results } = useSearchClient<T[]>(searchPath.value, { transformData })
    await fetchResults({ ...emptyQuery, fields }, criterions, limit, offset)
    return [...(results.value ?? [])]
  }

  const findChildren = async (
    parentLocationIds: number[],
    contentTypeIdentifiers: ContentType[] = [],
    gradeCodes: GradeCode[] = [],
    sortField: SortField = 'priority',
    sortOrder: SortOrder = 'asc',
    extraFields: string[] = [],
  ): Promise<BaseItem[]> => {
    if (!parentLocationIds.filter(truthy).length) {
      console.warn('Missing parentLocationIds in getChildren')
      return []
    }

    return await findContents<BaseItem>({
      parentLocationIdCriterion: parentLocationIds,
      contentTypeCriterion: contentTypeIdentifiers,
      gradeFieldCriterion: gradeCodes,
      sortField,
      sortOrder,
    }, 250, 0, [...cardFields, ...extraFields])
  }

  const findImageContent = async (contentId: number): Promise<ContentImage|undefined> => {
    return (await findContents<ContentImage>({
      contentIdCriterion: [contentId],
      contentTypeCriterion: [ContentType.Image],
      subtreeCriterion: [Subtree.Content, Subtree.Media],
      mainLocationCriterion: true,
    }, 1, 0))[0]
  }

  const findSvgContent = async (contentId: number): Promise<ContentSvg|undefined> => {
    return (await findContents<ContentSvg>({
      contentIdCriterion: [contentId],
      contentTypeCriterion: [ContentType.Svg],
      subtreeCriterion: [Subtree.Content, Subtree.Media],
      mainLocationCriterion: true,
    }, 1, 0))[0]
  }

  const getSettingsMessages = async () => {
    const response = await contentClient.get('/settings/messages', {
      transformRequest: [(data, headers) => {
        headers['X-Siteaccess'] = userPreferredLanguage.value
        delete headers['Authorization']
        return JSON.stringify(data)
      }],
    })
    return response.data
  }

  return {
    findContents,
    findImageContent,
    findSvgContent,
    findChildren,
    getSettingsMessages,
    getProductHeadersBySubjectAndGrade,
    getProductHeadersByLocationId,
  }
}
