<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { GradeCode } from '~/models/Grade'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { KsButton } from '@aschehoug/kloss'
import arrayUtils from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import { ContentType } from '~/models/Content/ContentType'
import useContentApi from '~/api/contentApi'

const props = defineProps<{
  subjectCode: SubjectCode
  gradeCode: GradeCode
}>()

const { t } = useI18n()
const router = useRouter()
const { truthy } = arrayUtils()
const { findContents } = useContentApi()
const { getRelatedLocationsByGradeAndSubject } = useProductStore()

const teacherLocation = ref<BaseItem>()

const teacherRoute = computed(() => teacherLocation.value?.contentTypeIdentifier === ContentType.TeacherArticle
  ? { name: 'teacher_subject', params: { subject: props.subjectCode.toLowerCase(), grade: props.gradeCode } }
  : { name: 'teacher_subject_legacy', params: { subject: props.subjectCode.toLowerCase() } }
)

watch(props, async () => {
  if (!(props.gradeCode && props.subjectCode)) return
  const { forTeacherLocationIds } = await getRelatedLocationsByGradeAndSubject(props.gradeCode, props.subjectCode)
  if (!forTeacherLocationIds.filter(truthy).length) return
  teacherLocation.value = (await findContents<BaseItem>({ locationIdCriterion: forTeacherLocationIds }))[0]
}, { immediate: true, deep: true })
</script>
<template>
  <KsButton
    v-if="teacherLocation"
    variant="border"
    size="medium"
    shape="rounded"
    @click.prevent="router.push(teacherRoute)"
  >
    {{ t('filters.forTeacher') }}
  </KsButton>
</template>
