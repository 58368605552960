<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton } from '@aschehoug/kloss'

const props = defineProps<{ copyright: string; isAnimation?: boolean }>()

const { t } = useI18n()

const isOpen = ref(false)

const copyrightText = computed(() => props.isAnimation
  ? t('copyright.animation', { copyright: props.copyright })
  : t('copyright.image', { copyright: props.copyright }))
</script>

<template>
  <div
    v-if="!!copyright"
    class="relative flex w-full items-center"
  >
    <div
      class="absolute right-12 z-10"
      aria-live="polite"
    >
      <Transition
        enter-active-class="duration-200 ease"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <p
          v-if="isOpen && copyright"
          class="w-max max-w-prose rounded-lg border border-gray-20 bg-white px-4 py-2 text-seagreen-60 shadow"
          v-text="copyrightText"
        />
      </Transition>
    </div>
    <KsButton
      class="ml-auto"
      :aria-label="isOpen ? t('copyright.close') : t('copyright.open')"
      :icon-left="isOpen ? 'xmark' : 'copyright'"
      variant="secondary"
      shape="square"
      size="small"
      @click="isOpen = !isOpen"
    />
  </div>
</template>
