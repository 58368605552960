<script lang="ts" setup>
import type { SubjectCode, SubjectComponentProps } from '~/models/Subject'
import { computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { colorMap, getColorAndShade } from '~/utils/colors'
import useSubjectsStore from '~/stores/subjects'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'
import { PendoFeature } from '~/models/Pendo'
import { useAppColor } from '~/composables/useAppColor'
import TeacherButton from '~/components/subject/TeacherButton.vue'
import SubjectSearch from '~/components/subject/SubjectSearch.vue'
import ColophonButton from '~/components/subject/ColophonButton.vue'
import SubjectFilter from '~/components/ResourceFinder/SubjectFilter.vue'
import LegacySubjectButton from '~/components/ResourceFinder/LegacySubjectButton.vue'
import GradeFilter from '~/components/ResourceFinder/GradeFilter.vue'
import TransparentCard from '~/components/cards/TransparentCard.vue'

const props = defineProps<SubjectComponentProps>()

const { t } = useI18n()
const { selectedGrade, isLoading } = storeToRefs(useFilterStore())
const { isTeacher } = storeToRefs(useAuthStore())
const { hasLoaded: hasLoadedProducts } = storeToRefs(useProductStore())
const { currentSubject } = storeToRefs(useSubjectsStore())

const { clear: clearAppColor, set: setAppColor } = useAppColor()

const showTeacherButton = computed(() => isTeacher.value
  && hasLoadedProducts.value
  && selectedGrade.value
  && props.subjectCode)

const themeColor = computed(() => {
  if (!currentSubject.value) return 'Blue'
  return ({
    SAF: 'Green',
    ENG: 'Blue',
    NOR: 'Green',
    KRL: 'Coral'
  } as Partial<Record<SubjectCode, string>>)[currentSubject.value] ?? 'Blue'
})

const themeColorValue = computed(() => getColorAndShade(themeColor.value, '60'))
const skeletonColor = computed(() => colorMap.get(getColorAndShade(themeColor.value, '50'))?.rgb)

onMounted(() => setAppColor(themeColorValue.value))

// Update app color when changing subject from dropdown
watch(themeColor, () => {
  if (!themeColor.value) return
  clearAppColor()
  setAppColor(themeColorValue.value)
})
</script>

<template>
  <div class="relative mx-auto mb-20 size-full max-w-screen-au px-4 sm:px-8">
    <div class="mx-auto mb-16 flex-1 space-y-16 pt-24 transition-all">
      <section class="mx-auto max-w-screen-lg space-y-12">
        <div class="flex w-full flex-wrap gap-4">
          <SubjectFilter />
          <GradeFilter />
        </div>
      </section>

      <section class="space-y-4">
        <div class="flex items-end justify-between">
          <h1
            class="font-bold text-white fluid-text-lg"
            v-text="t('filters.chooseHeader')"
          />
          <div
            class="flex items-center gap-4"
            style="--ks-border: rgb(var(--au-yellow-30)); color: rgb(var(--au-yellow-30)); --ks-borderhoverfill: rgb(var(--au-yellow-30))"
          >
            <LegacySubjectButton
              v-if="isUpcoming"
              :subject-code="subjectCode"
            />
            <TeacherButton
              v-if="showTeacherButton"
              :subject-code="subjectCode"
              :grade-code="selectedGrade"
              :data-pendo="PendoFeature.TeacherContent.Subject"
            />
            <ColophonButton
              v-if="hasLoadedProducts"
              :subject-code="subjectCode"
              :grade-code="selectedGrade"
              :data-pendo="PendoFeature.Colophon.Subject"
            />
          </div>
        </div>
        <KsSkeletonWrapper
          v-if="isLoading"
          class="grid w-full grid-cols-1 gap-7 sm:grid-cols-2 md:grid-cols-3"
        >
          <KsSkeleton
            v-for="i in 5"
            :key="`skeleton-${i}`"
            height="250px"
            :background="skeletonColor"
          />
        </KsSkeletonWrapper>
        <ul
          v-else
          class="grid w-full grid-cols-1 gap-7 sm:grid-cols-2 md:grid-cols-3"
        >
          <li
            v-for="content in contents"
            :key="`card-${content.locationId}`"
          >
            <TransparentCard
              :resource="content"
              :theme-color="themeColor"
              loading="eager"
            />
          </li>
        </ul>
      </section>
    </div>
    <SubjectSearch
      v-if="hasLoadedProducts"
      :subject-code="subjectCode"
      :grade-code="selectedGrade"
      :theme-color="themeColor"
    />
  </div>
</template>

